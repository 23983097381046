const state = () => ({
    items: []
})

const getters = {
    isDownloadSelected: (state) => (uid) => {
        return state.items.filter(download => download.uid === uid).length !== 0
    },
    isVariantSelected: (state) => (uid) => {
        return state.items.filter(download => download.variants.filter(variant => variant.uid === uid).length !== 0).length !== 0
    }
}

const mutations = {
    setItems (state, payload) {
        state.items = [...payload]
    },
}

const actions = {
    updateItems ({ getters, dispatch }, payload) {
        const variant = payload.variant
        const download = payload.download
        if (getters.isVariantSelected(variant.uid)) {
            dispatch('removeItem', { variant, download })
        } else {
            dispatch('addItem', { variant, download })
        }
    },
    addItem ({ state, getters, commit }, payload) {
        const variant = payload.variant
        const download = payload.download
        if (getters.isDownloadSelected(download.uid)) {
            // if download is already existent, we just need to add the variant to the download
            commit('setItems', state.items.map(item => (item.uid === download.uid) ? { ...item, variants: [ ...item.variants, variant ]} : item))
        } else {
            // if download is not existent, we need to add the download and the variant
            commit('setItems', [ ...state.items, { uid: download.uid, name: download.name, variants: [ variant ] } ])
        }
    },
    removeItem ({ state, commit }, payload) {
        const variant = payload.variant
        const download = payload.download
        if (state.items.filter(item => (item.uid === download.uid && item.variants.length === 1)).length) {
            // remove download because there is only one variant
            commit('setItems', state.items.filter(item => item.uid !== download.uid))
        } else {
            // remove only the variant because there are other variants of the download
            commit('setItems', state.items.map(item => (item.uid === download.uid) ? { ...item, variants: item.variants.filter(itemVariant => itemVariant.uid !== variant.uid) } : item))
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
