<template>
  <div class="module-overlay module-overlay--default" :class="{'-open': show}">
    <div class="overlay__cta">
      <div class="btn -btn-alpha" @click="show = true"><span>{{ $t('order.btn') }}</span>
      </div>
    </div>
    <div class="overlay__overlay">
      <div class="overlay__background" @click="closeOverlay()"></div>
      <div class="overlay__content">
        <div class="overlay__close" @click="closeOverlay()"></div>
        <div class="content-wrapper">
          <form v-if="orderSubmitted === false" id="order" @submit.prevent="onSubmitOrderForm" ref="form">
            <div class="page__wrap">
              <div class="headline__wrap">
                <div class="content-item module-headline-paragraph">
                  <div class="headline-paragraph__headline">
                    <span class="heading gamma">{{ $t('form.title') }}</span>
                  </div>
                </div>
              </div>
              <div class="field__wrap l-2col-b1-50-50">
                <div class="col col-one">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.firstname" id="firstname" type="text" name="firstname" required="required">
                      <div class="input-background">
                        <label for="firstname" class="control-label">{{ $t('form.firstname.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-two">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.lastname" id="lastname" type="text" name="lastname" value="" required="required">
                      <div class="input-background">
                        <label for="lastname" class="control-label">{{ $t('form.lastname.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.street" id="street" type="text" name="street" required="required">
                      <div class="input-background">
                        <label for="street" class="control-label">{{ $t('form.street.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap l-2col-b1-50-50">
                <div class="col col-one">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.city" id="city" type="text" name="city" required="required">
                      <div class="input-background">
                        <label for="city" class="control-label">{{ $t('form.city.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-two">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.zip" id="zip" type="text" name="zip" value="" required="required">
                      <div class="input-background">
                        <label for="zip" class="control-label">{{ $t('form.zip.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.country" id="country" type="text" name="country" value="" required="required">
                      <div class="input-background">
                        <label for="country" class="control-label">{{ $t('form.country.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group">
                    <div class="input">
                      <input class="form-control" v-model="form.poBox" id="poBox" type="text" name="poBox" value="">
                      <div class="input-background">
                        <label for="poBox" class="control-label">{{ $t('form.poBox.placeholder') }} {{ $t('form.optional') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.email" id="email" type="email" name="email" required="required">
                      <div class="input-background">
                        <label for="email" class="control-label">{{ $t('form.email.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.company" id="company" type="text" name="company" value="" required="required">
                      <div class="input-background">
                        <label for="company" class="control-label">{{ $t('form.company.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group">
                    <div class="input">
                      <input class="form-control" v-model="form.department" id="department" type="text" name="department" value="">
                      <div class="input-background">
                        <label for="department" class="control-label">{{ $t('form.department.placeholder') }} {{ $t('form.optional') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col col-one">
                  <div class="form-group">
                    <div class="input">
                      <textarea class="form-control" v-model="form.coverletter" id="coverletter" name="coverletter"></textarea>
                      <div class="input-background">
                        <label for="coverletter" class="control-label">{{ $t('form.coverletter.placeholder') }} {{ $t('form.optional') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group ">
                    <div class="checkbox -required">
                      <label class="add-on form-check-label" for="privacy">
                        <input required="required" class="add-on" v-model="form.privacy" id="privacy" type="checkbox" name="privacy" value="true">
                        <span v-html="$t('form.privacy.text', {privacylink: privacylink})"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="actions">
                <nav class="form-navigation">
                  <div class="btn-toolbar" role="toolbar">
                    <div class="btn-group" role="group">
                      <span class="next submit">
                        <button class="btn -btn-alpha" type="submit" value="1">
                          <span>{{ $t('form.send') }}</span>
                        </button>
                      </span>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </form>
          <div v-if="orderLoaded === true" class="page__wrap page__wrap--loading">
            <div class="headline__wrap">
              <div class="content-item module-headline-paragraph">
                <div class="headline-paragraph__headline">
                  <span class="preloader-text" style="width: 75%"></span>
                  <span class="preloader-text" style="width: 100%"></span>
                  <span class="preloader-text" style="width: 50%"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="orderSuccess === true" class="headline__wrap result -success">
            <div class="content-item module-headline-paragraph">
              <div class="headline-paragraph__headline">
                <span class="heading gamma">{{ $t('form.success.headline') }}</span>
              </div>
              <div class="headline-paragraph__text">
                <p>{{ $t('form.success.text') }}</p>
              </div>
            </div>
          </div>
          <div v-if="orderError === true" class="headline__wrap result -error">
            <div class="content-item module-headline-paragraph">
              <div class="headline-paragraph__headline">
                <span class="heading gamma">{{ $t('form.error.headline') }}</span>
              </div>
              <div class="headline-paragraph__text">
                <p>{{ $t('form.error.text') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'Order',
  props: ['selectedVariants', 'show'],
  computed: {
    ...mapState({
      privacylink: 'privacylink'
    }),
    orderSubmitted: {
      get ()
      {
        return this.$store.state.order.submitted
      },
      set (payload)
      {
        this.$store.commit('order/setSubmitted', payload)
      }
    },
    orderSuccess: {
      get ()
      {
        return this.$store.state.order.success
      },
      set (payload)
      {
        this.$store.commit('order/setSuccess', payload)
      }
    },
    orderError: {
      get ()
      {
        return this.$store.state.order.error
      },
      set (payload)
      {
        this.$store.commit('order/setError', payload)
      }
    },
    orderLoaded: {
      get ()
      {
        return this.$store.state.order.loaded
      },
      set (payload)
      {
        this.$store.commit('order/setLoaded', payload)
      }
    },
    form: {
      get ()
      {
        return this.$store.state.order.form
      },
      set (payload)
      {
        this.$store.commit('order/setForm', payload)
      }
    }
  },
  methods: {
    closeOverlay: function ()
    {
      this.show = false
      this.$emit('close')
    },
    restOrder: function ()
    {
      this.orderLoaded = false
      this.orderSubmitted = false
      this.orderSuccess = false
      this.orderError = false
      this.form = {
        salutation: '',
        firstname: '',
        street: '',
        zip: '',
        city: '',
        country: '',
        poBox: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        department: '',
        coverletter: '',
        privacy: false,
        variants: '',
        downloadrequestGrecaptchaTokenForms: ''
      }
    },
    onSubmitOrderForm: function ()
    {
      this.orderLoaded = true
      const action = 'odu_forms_form',
          localGrecaptcha = window.grecaptcha,
          self = this
      localGrecaptcha.ready(() =>
      {
        localGrecaptcha.execute(window.tx_theme.constants.googlerecaptcha.public_key, { action })
            .then(token =>
            {
              self.submitOrderForm(token)
            })
      })
    },
    submitOrderForm: function (token)
    {
      const variants = this.selectedVariants.map(el => el.name + '-' + el.language)
      this.form.variants = variants.join('\n')
      const url = new URL(window.app.download_list.api_base + 'mail/download-order-print/')
      url.searchParams.set('tx_api_api[firstname]', this.form.firstname)
      url.searchParams.set('tx_api_api[lastname]', this.form.lastname)
      url.searchParams.set('tx_api_api[street]', this.form.street)
      url.searchParams.set('tx_api_api[zip]', this.form.zip)
      url.searchParams.set('tx_api_api[city]', this.form.city)
      url.searchParams.set('tx_api_api[country]', this.form.country)
      url.searchParams.set('tx_api_api[poBox]', this.form.poBox)
      url.searchParams.set('tx_api_api[email]', this.form.email)
      url.searchParams.set('tx_api_api[company]', this.form.company)
      url.searchParams.set('tx_api_api[department]', this.form.department)
      url.searchParams.set('tx_api_api[coverletter]', this.form.coverletter)
      url.searchParams.set('tx_api_api[privacy]', this.form.privacy)
      url.searchParams.set('tx_api_api[variants]', this.form.variants)
      url.searchParams.set('tx_api_api[grecaptchaTokenForms]', token)

      this.orderSubmitted = true
      axios.post(url)
          // eslint-disable-next-line no-unused-vars
          .then(response =>
          {
            this.orderLoaded = false
            this.orderSuccess = true
            this.handleTracking(variants.join(';'))
          })
          // eslint-disable-next-line no-unused-vars
          .catch(e =>
          {
            this.orderLoaded = false
            this.orderError = true
          })
    },
    handleTracking: function (variants)
    {
      if (window.dataLayer)
      {
        window.dataLayer.push({
          'pdforder': variants,
          'event': 'pdforder-event'
        })
      }
    }
  }
}
</script>
