import axios from 'axios'

export default function () {
    this.configuration = {
        api: {
            read: ''
        },
        params: {},
        selected: []
    }

    this.state = () => ({
        api: this.configuration.api,
        loaded: false,
        items: [],
        params: this.configuration.params,
        selected: this.configuration.selected
    })

    this.mutations = {
        setApi (state, payload) {
            state.api = { ...payload }
        },
        setLoaded (state, payload) {
            state.loaded = payload
        },
        setItems (state, payload) {
            state.items = [...payload]
        },
        setSelected (state, payload) {
            state.selected = payload
        }
    }

    this.actions = {
        async get ({ state, commit }) {
            commit('setLoaded', false)
            try {
                const response = await axios.get(window.app.download_list.api_base + state.api.read + '/', { params: { ...state.params } })
                commit('setItems', response.data)
                commit('setLoaded', true)
                return response
            } catch (e) {
                commit('setErrors', e, { root: true })
            }
        }
    }
}
