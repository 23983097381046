<template>
  <div :class="`module-download-filter module-download-filter--${layout}`" ref="container">
    <div class="filter__title" v-if="layout === 'multi-checkbox'" @click="showOptions = !showOptions">
      <span>{{ label }}</span>
    </div>
    <div class="filter__options" :class="{ '-multiple-groups': options.length > 1 }" v-if="showOptions">
      <div class="options__group" :class="{ '-checked': selectedGroups.includes(groupIndex) }" v-for="(group, groupIndex) in options" :key="`${identifier}-${groupIndex}`">
        <div class="group__title" v-if="layout === 'multi-checkbox' && group.title">
          <label :for="`${identifier}-${groupIndex}`">
            <input type="checkbox" :id="`${identifier}-${groupIndex}`" :value="groupIndex" v-model="selectedGroups" @change="changeGroup($event.target.checked)">
            <span>{{ group.title }}</span>
          </label>
        </div>
        <div class="group__options">
          <div class="options__option" :class="{ '-checked': selectedOptions.includes(option.uid) }" v-for="(option, key) in group.options" :key="`${identifier}-${groupIndex}-${key}`">
            <label :for="`${identifier}-${groupIndex}-${key}`">
              <input type="checkbox" :id="`${identifier}-${groupIndex}-${key}`" :value="option.uid" v-model="selectedOptions" @change="changeOption($event.target.checked, groupIndex)">
              <span>{{ option.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showOptions: false,
      selectedGroups: []
    }
  },
  props: {
    identifier: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    // [{ title: 'group1', options: [{ label: "option1", value: "1" }, { label: "option2", value: "2" }, ...] }, ...]
    options: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array,
      default: () => ([])
    },
    layout: {
      type: String,
      default: 'multi-checkbox'
    }
  },
  computed: {
    selectedOptions: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changeGroup (checked) {
      if (checked) {
        this.selectedOptions = this.selectedOptions.concat(this.options[event.target.value].options.map(option => option.uid))
      } else {
        this.selectedOptions = this.selectedOptions.filter(option => !this.options[event.target.value].options.map(option => option.uid).includes(option))
      }
    },
    changeOption (checked, groupIndex) {
      if (checked) {
        const allOptionsOfGroup = this.options[groupIndex].options.map(option => option.uid)
        const selectedOptionsOfGroup = this.selectedOptions.filter(option => allOptionsOfGroup.includes(option))
        if ([selectedOptionsOfGroup.length, selectedOptionsOfGroup.length + 1].includes(allOptionsOfGroup.length)) {
          this.selectedGroups.push(groupIndex)
        }
      } else {
        this.selectedGroups = this.selectedGroups.filter(group => group !== groupIndex)
      }
    }
  },
  created () {
    const self = this
    self.showOptions = (self.layout === 'tags')

    // check groups
    self.options.forEach((option, index) => {
      self.changeOption(true, index)
    })

    if (this.layout === 'multi-checkbox') {
      document.addEventListener('mousedown', event => {
        if (self.$refs && self.$refs.container && !self.$refs.container.contains(event.target)) {
          self.showOptions = false
        }
      })
    }
  }
}
</script>
