import AbstractFilter from './abstract-filter'

const moduleDataTypes = new AbstractFilter()

moduleDataTypes.configuration.api.read = 'download-data-types'
moduleDataTypes.configuration.selected = window.app.download_list.dataTypes

export default {
  namespaced: true,
  state: moduleDataTypes.state,
  mutations: moduleDataTypes.mutations,
  actions: moduleDataTypes.actions
}
