import Vue from 'vue'
import VueRouter from 'vue-router'
import Downloads from '@/views/Downloads.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Downloads',
    component: Downloads
  },
  {
    path: '/:products/:dataTypes/:industries/:categories',
    name: 'FilterByRouter',
    component: Downloads
  }
]

export default new VueRouter({
  base: process.env.BASE_URL,
  routes
})
