const state = () => ({
  loaded: false,
  submitted: false,
  success: false,
  error: false,
  form: {
    grecaptchaTokenForms: '',
    salutation: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    coverletter: '',
    privacy: false,
    variants: '',
  }
})


const mutations = {
  setSubmitted (state, payload) {
    state.submitted = payload
  },
  setSuccess (state, payload) {
    state.success = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  setForm (state, payload) {
    state.form = payload
  },
  setLoaded (state, payload) {
    state.loaded = payload
  },
}


export default {
  namespaced: true,
  state,
  mutations,
}
