<template>
  <div class="module-overlay module-overlay--default" :class="{'-open': show}">
    <div class="overlay__overlay">
      <div class="overlay__background"/>
      <div class="overlay__content">
        <div class="overlay__close" @click="$emit('close')"/>
        <div class="content-wrapper">
          <form v-bind:class="submitted === false? '' : 'is-hidden'" id="downloadRequest" @submit.prevent="onSubmitForm" ref="form">
            <div class="page__wrap">
              <div class="headline__wrap">
                <div class="content-item module-headline-paragraph">
                  <div class="headline-paragraph__headline">
                    <span class="heading gamma">{{ $t("form.download.title") }}</span>
                  </div>
                  <span v-if="$t('form.download.text')">{{ $t('form.download.text') }}</span>
                </div>
              </div>
              <div class="field__wrap l-2col-b1-50-50">
                <div class="col col-one selectbox">
                  <div class="form-group -filled">
                    <div class="input -required" ref="input">
                      <select class="form-control" v-model="form.salutation" id="salutation_download"
                              :class="{'-filled': form.salutation}"
                              required="required" name="salutation_download">
                        <option>{{ $t('form.salutation.none') }}</option>
                        <option value="mr">{{ $t('form.salutation.mr') }}</option>
                        <option value="mrs">{{ $t('form.salutation.mrs') }}</option>
                      </select>
                      <div class="input-background">
                        <label class="control-label" for="salutation_download">{{ $t('form.salutation.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap l-2col-b1-50-50">
                <div class="col col-one">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.firstname" id="firstname_download" type="text"
                             :class="{'-filled': form.firstname}" required="required">
                      <div class="input-background">
                        <label for="firstname_download" class="control-label">{{ $t('form.firstname.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-two">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.lastname" id="lastname_download" type="text"
                             :class="{'-filled': form.lastname}" value="" required="required">
                      <div class="input-background">
                        <label for="lastname_download" class="control-label">{{ $t('form.lastname.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap l-2col-b1-50-50">
                <div class="col col-one">
                  <div class="form-group">
                    <div class="input">
                      <input class="form-control" :class="{'-filled': form.company}" v-model="form.company" id="company" type="text">
                      <div class="input-background">
                        <label for="company" class="control-label">{{ $t('form.company.placeholder') }} {{ $t('form.optional') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-two">
                  <div class="form-group">
                    <div class="input">
                      <input class="form-control" :class="{'-filled': form.department}" v-model="form.department" id="department" type="text">
                      <div class="input-background">
                        <label for="department" class="control-label">{{ $t('form.department.placeholder') }} {{ $t('form.optional') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap l-2col-b1-50-50">
                <div class="col col-one">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" :class="{'-filled': form.zip}" v-model="form.zip" id="zip_download" type="text" required="required">
                      <div class="input-background">
                        <label for="zip_download" class="control-label">{{ $t('form.zip.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-two">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" :class="{'-filled': form.country}" v-model="form.country" id="country_download" type="text" required="required">
                      <div class="input-background">
                        <label for="country_download" class="control-label">{{ $t('form.country.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap l-2col-b1-50-50">
                <div class="col col-one">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" v-model="form.email" id="email_download" type="email" :class="{'-filled': form.email}" required="required">
                      <div class="input-background">
                        <label for="email_download" class="control-label">{{ $t('form.email.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col-two">
                  <div class="form-group">
                    <div class="input -required">
                      <input class="form-control" :class="{'-filled': form.phone}" v-model="form.phone" id="phone_download" type="text" required="required">
                      <div class="input-background">
                        <label for="phone_download" class="control-label">{{ $t('form.phone.placeholder') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field__wrap">
                <div class="col">
                  <div class="form-group ">
                    <div class="checkbox -required" :class="{'-checked': form.privacy}">
                      <label class="add-on form-check-label" for="privacy_download">
                        <input required="required" class="add-on" :class="{'-filled': form.privacy}" v-model="form.privacy" id="privacy_download" type="checkbox" name="privacy_download" value="true">
                        <span v-html="$t('form.privacy.text', {privacylink: privacylink})"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="actions">
                <nav class="form-navigation">
                  <div class="btn-toolbar" role="toolbar">
                    <div class="btn-group" role="group">
                      <span class="next submit">
                        <button class="btn -btn-alpha" type="submit" value="1">
                          <span>{{ $t('form.send') }}</span>
                        </button>
                      </span>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </form>
          <div v-if="loaded === true" class="page__wrap page__wrap--loading">
            <div class="headline__wrap">
              <div class="content-item module-headline-paragraph">
                <div class="headline-paragraph__headline">
                  <span class="preloader-text" style="width: 75%"></span>
                  <span class="preloader-text" style="width: 100%"></span>
                  <span class="preloader-text" style="width: 50%"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="success === true" class="headline__wrap result -success">
            <div class="content-item module-headline-paragraph">
              <div class="headline-paragraph__headline">
                <span class="heading gamma">{{ $t("form.download.success.headline") }}</span>
              </div>
              <div class="headline-paragraph__text">
                <p>{{ $t("form.download.success.text") }}</p>
                <a href="#" @click="$emit('download')"><p>{{ $t("form.download.link") }}</p></a>
              </div>
            </div>
          </div>
          <div v-if="error === true" class="headline__wrap result -error">
            <div class="content-item module-headline-paragraph">
              <div class="headline-paragraph__headline">
                <span class="heading gamma">{{ $t("form.error.headline") }}</span>
              </div>
              <div class="headline-paragraph__text">
                <p>{{ $t("form.error.text") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {mapState} from "vuex";

export default {
  name: 'DownloadForm',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    fileToDownload: {
      type: String
    }
  },
  computed: {
    ...mapState({
      privacylink: 'privacylink'
    }),
    submitted: {
      get() {
        return this.$store.state.downloadRequest.submitted
      },
      set(payload) {
        this.$store.commit('downloadRequest/setSubmitted', payload)
      }
    },
    success: {
      get() {
        return this.$store.state.downloadRequest.success
      },
      set(payload) {
        this.$store.commit('downloadRequest/setSuccess', payload)
      }
    },
    error: {
      get() {
        return this.$store.state.downloadRequest.error
      },
      set(payload) {
        this.$store.commit('downloadRequest/setError', payload)
      }
    },
    loaded: {
      get() {
        return this.$store.state.downloadRequest.loaded
      },
      set(payload) {
        this.$store.commit('downloadRequest/setLoaded', payload)
      }
    },
    form: {
      get() {
        return this.$store.state.downloadRequest.form
      },
      set(payload) {
        this.$store.commit('downloadRequest/setForm', payload)
      }
    }
  },
  methods: {
    reset: function () {
      this.loaded = false
      this.submitted = false
      this.success = false
      this.error = false
      this.form = {
        salutation: '',
        company: '',
        department: '',
        firstname: '',
        lastname: '',
        zip: '',
        country: '',
        email: '',
        phone: '',
        privacy: false,
        downloadrequestGrecaptchaTokenForms: ''
      }
    },
    onSubmitForm: function () {
      this.loaded = true
      const action = 'odu_forms_form',
          localGrecaptcha = window.grecaptcha,
          self = this
      if (typeof localGrecaptcha === 'object') {
        localGrecaptcha.ready(() => {
          localGrecaptcha.execute(window.tx_theme.constants.googlerecaptcha.public_key, { action })
              .then(token => {
                self.submitForm(token).then(() => {
                  localStorage.setItem('tx_odu_domain_model_downloadrequest', true)
                }).catch(error => {
                  throw error
                })
              }).catch(error => {
                console.log('Error: ' + error.message)
                this.error = true
              })
        })

      } else {
        self.submitForm(null).then(() => {
          localStorage.setItem('tx_odu_domain_model_downloadrequest', true)
        }).catch(error => {
          throw error
        })
      }
    },
    submitForm: function (token) {
      return new Promise((resolve, reject) => {
        const url = new URL(window.app.download_list.api_base + 'mail/download-request/')
        url.searchParams.set('tx_api_api[salutation]', this.form.salutation)
        url.searchParams.set('tx_api_api[company]', this.form.company)
        url.searchParams.set('tx_api_api[department]', this.form.department)
        url.searchParams.set('tx_api_api[firstname]', this.form.firstname)
        url.searchParams.set('tx_api_api[lastname]', this.form.lastname)
        url.searchParams.set('tx_api_api[zip]', this.form.zip)
        url.searchParams.set('tx_api_api[country]', this.form.country)
        url.searchParams.set('tx_api_api[email]', this.form.email)
        url.searchParams.set('tx_api_api[phone]', this.form.phone)
        url.searchParams.set('tx_api_api[file]', this.fileToDownload)
        url.searchParams.set('tx_api_api[privacy]', this.form.privacy)
        url.searchParams.set('tx_api_api[grecaptchaTokenForms]', token)

        this.submitted = true
        axios.post(url)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.loaded = false
              this.success = true
              this.$emit('download')
              resolve(response)
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.loaded = false
              this.error = true
              reject(error)
            })
      })
    }
  }
}
</script>
