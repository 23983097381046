<template>
  <div class="module-keywordfilter form">
    <div class="form-group">
      <div class="input">
        <input id="keywordfilter" :placeholder="$t('search.keywordfilter')" class="form-control" :class="{ '-filled': keywordfilter }" type="text" name="keywordfilter" v-model="keywordfilter" @input="debouncedSearch">
        <div class="input-background">
          <label class="control-label " for="keywordfilter">{{ $t('search.keywordfilter') }}</label>
        </div>
      </div>
    </div>
  </div>

</template>
<script>


import {mapActions, mapState} from "vuex";
import { debounce } from "debounce";

export default {
  name: 'KeywordFilter',
  computed: {
    keywordfilter: {
      get () {
        return this.$store.state.keywordfilter
      },
      set (payload) {
        this.$store.commit('setKeywordFilter', payload)
      }
    },
      ...mapState([
          'downloads',
          'downloads/loaded'
      ])
  },
  methods: {
    filterByKeyword: function () {
      this.filter()
      if (this.keywordfilter !== '' && window.dataLayer)
      {
        window.dataLayer.push({
          'event': 'download-search-event',
          'download-tag': this.keywordfilter
        })
      }
    },
    ...mapActions([
        'filter'
    ])
  },
  created () {
    this.debouncedSearch = debounce(this.filterByKeyword, 500)
  },
}
</script>
