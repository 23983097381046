import axios from "axios";

const state = () => ({
  loaded: false,
  apiRead: 'download-taggroup'
})


const getters = {
  getParams: (state, getters, rootState) => {
    const params = {}
    params.uid = {
      equals: rootState.selectedTaggroup
    }
    return params
  }
}

const mutations = {
  setLoaded (state, payload) {
    state.loaded = payload
  }
}

const actions = {
  async get ({ state, commit }, payload) {
    commit('setLoaded', false)
    try {
      const response = await axios.get(window.app.download_list.api_base + state.apiRead + '/'+ payload + '/')
      if (response.data && response.data.industries) {
        commit('industries/setItems', Object.values(response.data.industries), { root: true })
      }
      if (response.data && response.data.categories) {
        commit('categories/setItems', Object.values(response.data.categories), { root: true })
      }
      if (response.data && response.data.dataTypes) {
        commit('dataTypes/setItems', Object.values(response.data.dataTypes), { root: true })
      }
      if (response.data && response.data.products) {
        commit('products/setItems', Object.values(response.data.products), { root: true })
      }
      commit('setLoaded', true)
      return response
    } catch (e) {
      commit('setErrors', e, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
