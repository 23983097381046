import AbstractFilter from './abstract-filter'

const moduleIndustries = new AbstractFilter()

moduleIndustries.configuration.api.read = 'download-industries'
moduleIndustries.configuration.selected = window.app.download_list.industries

export default {
  namespaced: true,
  state: moduleIndustries.state,
  mutations: moduleIndustries.mutations,
  actions: moduleIndustries.actions
}
