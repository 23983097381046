import AbstractFilter from './abstract-filter'

const moduleProducts = new AbstractFilter()

moduleProducts.configuration.api.read = 'products'
moduleProducts.configuration.params = window.app.download_list.productCategory.localizedUid ? { category: { equals: window.app.download_list.productCategory.localizedUid } } : {}
moduleProducts.configuration.selected = (window.app.download_list.productId >= 0) ? [window.app.download_list.productId] : []


export default {
  namespaced: true,
  state: moduleProducts.state,
  mutations: moduleProducts.mutations,
  actions: moduleProducts.actions
}
