<template>
    <div class="section-row tx-odu-download--list -padded-wide -bg-alpha-light" :class="{'-move-up': showTags != '0'}">

        <download-form :show="showDownloadForm" :file-to-download="fileToDownload"
                       @download="downloadItem(fileToDownload)"
                       @close="closeDownloadForm()" ref="downloadForm"/>

        <div class="col-wrapper -max-width-limited" style="margin-bottom: 0">
            <div class="module-filter-form" ref="filterform" v-if="showTags === '0'">
                <div class="filter-form__form form">
                    <div class="form-wrapper -bg-white">
                        <div class="col">
                            <KeywordFilter v-if="initialized"></KeywordFilter>
                        </div>
                    </div>
                    <div class="form-wrapper -bg-white" v-if="(fixedProduct === 0 && !hideProductFilter && products.length) || (industries.length && fixedIndustry === 0) || (!hideDataTypeFilter && dataTypes.length) || (!hideCategoryFilter && categories.length)">
                        <div class="col" v-if="fixedProduct === 0 && !hideProductFilter && products.length">
                            <DownloadFilter
                                    identifier="products"
                                    :label="$t('search.products')"
                                    :options="mutatedProducts"
                                    v-model="selectedProducts"/>
                        </div>
                        <div class="col" v-if="industries.length && fixedIndustry === 0">
                            <DownloadFilter
                                    identifier="industries"
                                    :label="$t('search.industries')"
                                    :options="[{ options: industries }]"
                                    v-model="selectedIndustries"/>
                        </div>
                        <div class="col" v-if="!hideDataTypeFilter && dataTypes.length">
                            <DownloadFilter
                                    identifier="dataTypes"
                                    :label="$t('search.dataTypes')"
                                    :options="[{ options: dataTypes }]"
                                    v-model="selectedDataTypes"/>
                        </div>
                        <div class="col" v-if="!hideCategoryFilter && categories.length">
                            <DownloadFilter
                                    identifier="categories"
                                    :label="$t('search.categories')"
                                    :options="[{ options: categories }]"
                                    v-model="selectedCategories"/>
                        </div>
                    </div>
                </div>
                <div class="filter-form__selected"
                     v-if="(fixedProduct === 0 && !hideProductFilter && selectedProducts.length) || (fixedIndustry === 0 && selectedIndustries.length) || (!hideDataTypeFilter && selectedDataTypes.length) || (!hideCategoryFilter && selectedCategories.length)">
                    <div class="selected__wrapper">
                        <template v-if="fixedProduct === 0 && !hideProductFilter">
                            <div class="selected__item"
                                 v-for="product in products.filter(item => selectedProducts.includes(item.uid))"
                                 :key="`product-selected-${product.uid}`"
                                 @click="selectedProducts = selectedProducts.filter(selected => selected !== product.uid)">
                                <span class="icon-times-after">{{ product.name }}</span>
                            </div>
                        </template>
                        <template v-if="fixedIndustry === 0">
                            <div class="selected__item"
                                 v-for="industry in industries.filter(item => selectedIndustries.includes(item.uid))"
                                 :key="`industry-selected-${industry.uid}`"
                                 @click="selectedIndustries = selectedIndustries.filter(selected => selected !== industry.uid)">
                                <span class="icon-times-after">{{ industry.name }}</span>
                            </div>
                        </template>
                        <template v-if="!hideCategoryFilter">
                            <div class="selected__item"
                                 v-for="category in categories.filter(item => selectedCategories.includes(item.uid))"
                                 :key="`category-selected-${category.uid}`"
                                 @click="selectedCategories = selectedCategories.filter(selected => selected !== category.uid)">
                                <span class="icon-times-after">{{ category.name }}</span>
                            </div>
                        </template>
                        <template v-if="!hideDataTypeFilter">
                            <div class="selected__item"
                                 v-for="dataType in dataTypes.filter(item => selectedDataTypes.includes(item.uid))"
                                 :key="`dataType-selected-${dataType.uid}`"
                                 @click="selectedDataTypes = selectedDataTypes.filter(selected => selected !== dataType.uid)">
                                <span class="icon-times-after">{{ dataType.name }}</span>
                            </div>
                        </template>
                    </div>
                    <div class="selected__reset">
                        <span class="link" @click="reset()"><span>{{ $t('search.reset') }}</span></span>
                    </div>
                </div>
            </div>
            <div class="module-filter-tags" v-else>
                <div class="filter-tags-wrapper">
                    <KeywordFilter v-if="initialized"></KeywordFilter>
                    <div class="tags-wrapper" v-if="initialized">
                        <div class="tags__tags">
                            <DownloadFilter
                                    identifier="products"
                                    :label="$t('search.products')"
                                    :options="mutatedProducts"
                                    layout="tags"
                                    v-model="selectedProducts"/>
                            <DownloadFilter
                                    identifier="dataTypes"
                                    :label="$t('search.dataTypes')"
                                    :options="[{ options: dataTypes }]"
                                    layout="tags"
                                    v-model="selectedDataTypes"/>
                            <DownloadFilter
                                    identifier="categories"
                                    :label="$t('search.categories')"
                                    :options="[{ options: categories }]"
                                    layout="tags"
                                    v-model="selectedCategories"/>
                            <DownloadFilter
                                    identifier="industries"
                                    :label="$t('search.industries')"
                                    :options="[{ options: industries }]"
                                    layout="tags"
                                    v-model="selectedIndustries"/>
                        </div>
                        <div class="selected__reset">
                            <span class="link" @click="reset()"><span>{{ $t('search.reset') }}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-wrapper l-2col-b3-75-25 l-2col-b3-75-25--normal -max-width-limited" ref="colWrapper">
            <div class="col-one" ref="downloadsCol">
                <div class="content-item module-downloads">
                    <div class="downloads-wrapper">
                        <template>
                            <template v-for="(item, index) in downloads">
                                <div class="download__item -bg-white"
                                     :key="`download__item--${item.localizedUid}`">
                                    <div v-if="item.image" class="item__image" :id="'item__image'+index">
                                        <img :src="item.image.split('|')[0]" :title="item.image.split('|')[1]"
                                             :alt="item.image.split('|')[2]">
                                    </div>
                                    <div class="item__content">
                                        <div class="content__headline">
                    <span class="heading zeta -color-text-alpha">
                      {{ item.name }}<template v-if="item.dataType && item.dataType.suffix"> – {{
                            item.dataType.suffix
                        }}</template>
                    </span>
                                        </div>
                                    </div>
                                    <div v-if="item.variants" class="item__variants">
                                        <div class="variants__variant" v-for="variant in item.variants" :key="variant.localizedUid">
                                            <div class="variant__language">
                                                <span class="language__flag" :class="'language__flag--' + variant.language"></span>
                                                <span class="language__name meta">{{ $t('language.' + variant.language) }}</span>
                                            </div>
                                            <div v-if="variant.isOrder" @click="toggleVariant(variant, item)"
                                                 class="variant__action variant__action--form icon-cart-before"
                                                 :class="{ '-selected': isVariantSelected(variant.uid) }"></div>
                                            <a v-if="variant.file" @click="handleDownload(item, variant)" target="_blank"
                                               class="variant__action variant__action--file icon-download-before"></a>
                                            <a v-if="variant.link" @click="handleShow(variant)" target="_blank"
                                               class="variant__action variant__action--link icon-book-before"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="module-teaser" :key="`mediacentre-teaser-${index}`" v-if="mediacentreLink && downloads.length >= 6 && index === 5">
                                    <div class="teaser__image" :style="'background: url(' + require('@/assets/img/odu-connects-home-image-mediathek.jpg') + ') no-repeat 50% 50%/cover'"></div>
                                    <div class="teaser__content">
                                        <div class="content__title">
                                            <span class="heading delta -color-white">{{ $t('search.mediacentreTeaser.title') }}</span>
                                        </div>
                                        <div class="content__link">
                                            <a :href="mediacentreLink" class="btn -btn-alpha">
                                                <span>{{ $t('search.mediacentreTeaser.link') }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="download__item download__item--placeholder"
                                 v-if="initialized && downloadsLoad && downloads.length !== 0"></div>
                            <div class="no-results-wrapper" v-if="initialized && downloadsLoad && downloads.length === 0">
                                {{ $t('search.no-results') }}
                            </div>
                            <div class="module-teaser" :key="`mediacentre-teaser-${index}`" v-if="initialized && downloadsLoad && mediacentreLink && downloads.length !== 0 && downloads.length < 6">
                                <div class="teaser__image" :style="'background: url(' + require('@/assets/img/odu-connects-home-image-mediathek.jpg') + ') no-repeat 50% 50%/cover'"></div>
                                <div class="teaser__content">
                                    <div class="content__title">
                                        <span class="heading delta -color-white">{{ $t('search.mediacentreTeaser.title') }}</span>
                                    </div>
                                    <div class="content__link">
                                        <a :href="mediacentreLink" class="btn -btn-alpha">
                                            <span>{{ $t('search.mediacentreTeaser.link') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="!initialized || !downloadsLoad">
                            <div class="download__item download__item--loading" v-for="n in itemsPerPage"
                                 :key="`download__item--${n}-loading`">
                                <div class="item__image">
                                    <span class="preloader-image"></span>
                                </div>
                                <div class="item__content">
                                    <div class="content__headline">
                                        <span class="preloader-text"></span>
                                    </div>
                                    <div class="content__language">
                                        <span class="preloader-text"></span>
                                        <span class="preloader-text"></span>
                                    </div>
                                    <div class="content__actions">
                                        <span class="preloader-text"></span>
                                        <span class="preloader-text"></span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="content-item module-load-more" v-if="numberOfTotalItems">
                    <div class="load-more__progress" v-if="initialized">
                        <div class="progress__info">
              <span class="meta"
                    v-html="$t('loadMore.info', { current: downloads.length, total: numberOfTotalItems })"></span>
                        </div>
                        <div class="progress__bar"><span
                                :style="{ width: (downloads.length / numberOfTotalItems * 100) + '%' }"></span></div>
                    </div>
                </div>
            </div>
            <div class="col-two col-two--download-order" ref="orderCol">
                <div class="module-download-order" ref="order">
                    <div class="download-order__wrapper -bg-white" :class="{'-hide': !showMobileDownloadOverlay, '-mobile': isMobile}"
                         ref="downloadOrderWrapper">
                        <div class="order__title">
                            <span class="heading epsilon">{{ $t('order.title') }}</span>
                        </div>
                        <div class="order__items">
                            <template v-for="download in cartItems">
                                <div class="items__item meta" :key="download.uid">
                                    {{ download.name }}
                                    <span v-for="variant in download.variants" :key="variant.uid" class="item__variant">
                      <span class="variant__flag" :class="'variant__flag--' + variant.language"></span>
                      <span class="variant__remove" @click="toggleVariant(variant, download)"></span>
                    </span>
                                </div>
                            </template>
                            <div v-if="cartItems.length === 0">
                                <p>{{ $t('order.no-selected') }}</p>
                            </div>
                        </div>
                        <div class="order__action">
                            <Order v-bind:selectedVariants="(cartItems.map(download => download.variants).flat(2))" :show="showOrderForm" @close="closeOrderForm()"></Order>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module-download-mobile" :class="{'-top': showMobileDownloadOverlay}">
            <div class="download-button" @click="showMobileDownloadOverlay = !showMobileDownloadOverlay">
                <span :class="{'icon-cart-before': !showMobileDownloadOverlay, 'close-icon': showMobileDownloadOverlay}"></span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Order from '@/components/Order'
import DownloadForm from '@/components/DownloadForm'
import KeywordFilter from '../components/KeywordFilter'
import DownloadFilter from '@/components/DownloadFilter'

export default {
    data ()
    {
        return {
            isFetchingData: false,
            showDownloadForm: false,
            showOrderForm: false,
            fileToDownload: null,
            itemToDownload: null,
            showMobileDownloadOverlay: false,
            isMobile: false,
            mediacentreLink: window.app.download_list.mediacentreListLink,
            hideProductFilter: window.app.download_list.hideProductFilter === '1',
            hideDataTypeFilter: window.app.download_list.hideDataTypeFilter === '1',
            hideCategoryFilter: window.app.download_list.hideCategoryFilter === '1'
        }
    },
    computed: {
        selectedIndustries: {
            get ()
            {
                return this.$store.state.industries.selected
            },
            set (payload)
            {
                this.$store.commit('industries/setSelected', payload)
                if (!this.isFetchingData) this.submit()
            }
        },
        selectedCategories: {
            get ()
            {
                return this.$store.state.categories.selected
            },
            set (payload)
            {
                this.$store.commit('categories/setSelected', payload)
                if (!this.isFetchingData) this.submit()
            }
        },
        selectedDataTypes: {
            get ()
            {
                return this.$store.state.dataTypes.selected
            },
            set (payload)
            {
                this.$store.commit('dataTypes/setSelected', payload)
                if (!this.isFetchingData) this.submit()
            }
        },
        selectedProducts: {
            get ()
            {
                return this.$store.state.products.selected
            },
            set (payload)
            {
                this.$store.commit('products/setSelected', payload)
                if (!this.isFetchingData) this.submit()
            }
        },
        mutatedProducts ()
        {
            const products = this.products
            const categories = []
            products.forEach(product =>
            {
                if (product.category && !categories.includes(product.category.name)) categories.push(product.category.name)
            })
            const mutatedProducts = []
            categories.forEach(category =>
            {
                mutatedProducts.push({
                    title: category,
                    options: products.filter(product => product.category && product.category.name === category)
                })
            })
            if (mutatedProducts.length !== 1)
            {
                mutatedProducts.push({
                    title: this.$i18n.t('search.productDefaultCategory'),
                    options: products.filter(product => !product.category)
                })
            }
            return mutatedProducts
        },
        ...mapState({
            initialized: state => state.initialized,
            showTags: state => state.showTags,
            fixedProduct: state => state.fixedProduct,
            fixedIndustry: state => state.fixedIndustry,
            productCategory: state => state.productCategory,
            industries: state => state.industries.items,
            categories: state => state.categories.items,
            dataTypes: state => state.dataTypes.items,
            products: state => state.products.items,
            downloads: state => state.downloads.items,
            downloadsLoad: state => state.downloads.loaded,
            currentPage: state => state.downloads.currentPage,
            itemsPerPage: state => state.downloads.itemsPerPage,
            numberOfPages: state => state.downloads.numberOfPages,
            numberOfTotalItems: state => state.downloads.numberOfTotalItems,
            cartItems: state => state.cart.items
        }),
        ...mapGetters('cart', [
            'isVariantSelected'
        ])
    },
    watch: {
        '$route': function ()
        {
            this.fetchData()
            this.filter()
        }
    },
    components: {
        DownloadFilter,
        KeywordFilter,
        Order,
        DownloadForm
    },
    methods: {
        ...mapActions([
            'init',
            'filter',
            'loadMore',
            'clearKeywordsearch'
        ]),
        ...mapActions('cart', {
            updateVariants: 'updateItems'
        }),
        fetchData: function ()
        {
            this.isFetchingData = true
            if (this.$route.params.industries && this.$route.params.industries !== '-1')
            {
                this.selectedIndustries = this.$route.params.industries.split(',')
                    .map(item => parseInt(item))
            }
            if (this.$route.params.categories && this.$route.params.categories !== '-1')
            {
                this.selectedCategories = this.$route.params.categories.split(',')
                    .map(item => parseInt(item))
            }
            if (this.$route.params.dataTypes && this.$route.params.dataTypes !== '-1')
            {
                this.selectedDataTypes = this.$route.params.dataTypes.split(',')
                    .map(item => parseInt(item))
            }
            if (this.fixedProduct === 0 && this.$route.params.products && this.$route.params.products !== '-1')
            {
                this.selectedProducts = this.$route.params.products.split(',')
                    .map(item => parseInt(item))
            }
            this.isFetchingData = false
        },
        submit: function ()
        {
            let selectedString = ''
            if (this.selectedIndustries.length)
            {
                selectedString += this.industries.filter(item => this.selectedIndustries.includes(item.uid))
                    .map(item => item.name)
            }
            if (this.selectedCategories.length)
            {
                selectedString += this.categories.filter(item => this.selectedCategories.includes(item.uid))
                    .map(item => item.name)
            }
            if (this.selectedDataTypes.length)
            {
                selectedString += this.dataTypes.filter(item => this.selectedDataTypes.includes(item.uid))
                    .map(item => item.name)
            }
            if (this.selectedProducts.length)
            {
                if (selectedString.length) selectedString += ','
                selectedString += this.products.filter(item => this.selectedProducts.includes(item.uid))
                    .map(item => item.name)
            }
            if (selectedString !== '' && window.dataLayer)
            {
                window.dataLayer.push({
                    'event': 'download-tag-event',
                    'download-tag': selectedString
                })
            }

            this.$router.push({
                name: 'FilterByRouter',
                params: {
                    products: this.fixedProduct === 0 && this.selectedProducts.length ? this.selectedProducts.toString() : '-1',
                    dataTypes: this.selectedDataTypes.length ? this.selectedDataTypes.toString() : '-1',
                    industries: this.selectedIndustries.length ? this.selectedIndustries.toString() : '-1',
                    categories: this.selectedCategories.length ? this.selectedCategories.toString() : '-1'
                }
            })
        },
        reset: function ()
        {
            this.isFetchingData = true
            if (this.fixedIndustry === 0) this.selectedIndustries = []
            if (!this.hideCategoryFilter) this.selectedCategories = []
            if (!this.hideDataTypeFilter) this.selectedDataTypes = []
            if (this.fixedProduct === 0 && !this.hideProductFilter) this.selectedProducts = []
            this.isFetchingData = false
            this.submit()
        },
        toggleVariant: function (variant, download)
        {
            this.showMobileDownloadOverlay = true
            this.updateVariants({
                variant,
                download
            })
        },
        handleShow: function (item)
        {
            if (window.dataLayer && item)
            {
                window.dataLayer.push({
                    'document-view': item.name,
                    'document-view-link': item.link,
                    'event': 'document-view-event'
                })
            }
            window.open(item.link, '_blank')
                .focus()
        },
        handleDownload: function (item, variant)
        {
            const alreadySubmitted = localStorage.getItem('tx_odu_domain_model_downloadrequest') === 'true',
                locale = window.app.download_list.locale
            if (item.restricted && (!alreadySubmitted || locale === 'fr-FR'))
            {
                this.handleRestrictiveDownload(variant.file, item)
                document.querySelector('html')
                    .classList
                    .add('-overlay-open')
                return
            }
            this.handleTracking(item)
            this.downloadItem(variant.file)
        },
        closeDownloadForm: function ()
        {
            this.showDownloadForm = false
            if (window.dataLayer)
            {
                window.dataLayer.push({
                    'event': 'pdf-download-canceled-event'
                })
            }
            document.querySelector('html')
                .classList
                .remove('-overlay-open')
        },
        closeOrderForm: function ()
        {
            this.showOrderForm = false
            if (window.dataLayer)
            {
                window.dataLayer.push({
                    'event': 'pdf-order-canceled-event'
                })
            }
            document.querySelector('html')
                .classList
                .remove('-overlay-open')
        },
        handleRestrictiveDownload: function (file, item)
        {
            this.showDownloadForm = true
            this.fileToDownload = file
            this.itemToDownload = item
            this.$refs.downloadForm.reset()
        },
        downloadItem: function (file)
        {
            this.handleTracking(this.itemToDownload)
            window.open(file, '_blank')
                .focus()
        },
        handleTracking: function (item)
        {
            if (window.dataLayer && item)
            {
                var eventVar = item.name
                if (item && item.dataType && item.dataType.suffix)
                {
                    eventVar = item.name + ' - ' + item.dataType.suffix
                }
                window.dataLayer.push({
                    'pdfdownload': eventVar,
                    'event': 'pdfdownload-event'
                })
            }
        },
        setDownloadOrderPosition: function ()
        {
            let orderCol = this.$refs.orderCol
            let order = this.$refs.order
            if (orderCol)
            {
                let orderColRec = orderCol.getBoundingClientRect()
                let orderWidth = orderColRec.width
                let orderLeftPosition = orderColRec.left
                let startFixed = orderColRec.top
                let endFixed = (parseFloat(orderColRec.height) - parseFloat(order.getBoundingClientRect().height)) + startFixed
                if (window.innerWidth >= 1440)
                {
                    this.isMobile = false
                    if (startFixed > 0)
                    {
                        order.style.position = 'absolute'
                        order.style.top = '0'
                        order.style.bottom = 'auto'
                        order.style.left = '0'
                        order.style.width = '100%'
                    }
                    else if (startFixed < 0 && endFixed > 0)
                    {
                        order.style.position = 'fixed'
                        order.style.top = '0'
                        order.style.bottom = 'auto'
                        order.style.left = orderLeftPosition + 'px'
                        order.style.width = orderWidth + 'px'
                    }
                    else if (startFixed < 0 && endFixed < 0)
                    {
                        order.style.position = 'absolute'
                        order.style.top = 'auto'
                        order.style.bottom = '0'
                        order.style.left = '0'
                        order.style.width = '100%'
                    }
                }
                else
                {
                    this.isMobile = true
                    order.removeAttribute('style')
                }
            }

        },
        inifiniteScroll: function ()
        {
            if ((this.$refs.colWrapper.offsetHeight < (window.innerHeight - this.$refs.colWrapper.getBoundingClientRect().top)) && this.currentPage < this.numberOfPages && this.downloadsLoad)
            {
                this.loadMore()
            }
        }
    },
    beforeCreate ()
    {
        window.addEventListener('ready', this.setDownloadOrderPosition)
        window.addEventListener('load', this.setDownloadOrderPosition)
    },
    created ()
    {
        this.fetchData()
        this.init()
        window.addEventListener('ready', this.setDownloadOrderPosition)
        window.addEventListener('load', this.setDownloadOrderPosition)
        window.addEventListener('scroll', this.setDownloadOrderPosition)
        window.addEventListener('scroll', this.inifiniteScroll)
        window.addEventListener('resize', this.setDownloadOrderPosition)
    }
}
</script>
