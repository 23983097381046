import Vue from 'vue'
import VueI18n from 'vue-i18n'
import merge from 'lodash.merge'

Vue.use(VueI18n)

function loadMessages () {
  let locales = require.context('./messages', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matchedDefaultLocale = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matchedDefaultLocale && matchedDefaultLocale.length > 1) {
      const locale = matchedDefaultLocale[1]
      messages[locale] = locales(key)
    }
  })
  if (window.app.download_list.site) {
    locales.keys().forEach(key => {
      const regexp = new RegExp(`${window.app.download_list.site}/([A-Za-z0-9-_]+).`, 'i')
      const matchedSiteLocale = key.match(regexp)
      if (matchedSiteLocale && matchedSiteLocale.length > 1) {
        const locale = matchedSiteLocale[1]
        messages[locale] = merge(messages[locale], locales(key))
      }
    })
  }
  return messages
}

export default new VueI18n({
  locale: window.app.download_list.locale,
  fallbackLocale: 'en-GB',
  messages: loadMessages()
})
