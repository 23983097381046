import AbstractFilter from './abstract-filter'

const moduleCategories = new AbstractFilter()

moduleCategories.configuration.api.read = 'download-categories'
moduleCategories.configuration.selected = window.app.download_list.categories

export default {
  namespaced: true,
  state: moduleCategories.state,
  mutations: moduleCategories.mutations,
  actions: moduleCategories.actions
}
